<template>
  <div class="">
    <div class="pt-8">
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
        icon-pack="fas"
      >
        <b-step-item step="1" label="Account" :clickable="isStepsClickable">
          <div class="flex justify-center pt-7">
            <div class="w-1/3 height">
              <b-field label="Full Name" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.name"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>

              <b-field label="Gender" class="mb-5">
                <b-radio
                  v-model="studentDetails.gender"
                  name="gender"
                  native-value="Male"
                >
                  Male
                </b-radio>
                <b-radio
                  v-model="studentDetails.gender"
                  name="gender"
                  native-value="Female"
                >
                  Female
                </b-radio>
                <b-radio
                  v-model="studentDetails.gender"
                  name="gender"
                  native-value="Others"
                >
                  Others
                </b-radio>
              </b-field>
              <b-field label="Date Of Birth">
                <b-input
                  type="text"
                  v-model="studentDetails.dateofbirth"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>
              <b-field label="Father Name" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.father_name"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>
              <b-field label="Mother Name" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.mother_name"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="2"
          label="Address"
          :clickable="isStepsClickable"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <div class="flex justify-center pt-7">
            <div class="w-1/3 height">
              <b-field label="Address">
                <b-input
                  maxlength="200"
                  type="textarea"
                  v-model="studentDetails.address"
                  required
                ></b-input>
              </b-field>
              <b-field label="City" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.city"
                  required
                  maxlength="30"
                ></b-input>
              </b-field>
              <b-field label="Pin Code" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.pincode"
                  required
                  maxlength="6"
                ></b-input>
              </b-field>
              <b-field label="State" class="mb-0">
                <b-select
                  placeholder="Select State"
                  required
                  expanded
                  v-model="studentDetails.state"
                >
                  <option
                    v-for="(state, index) in states"
                    :key="index"
                    :value="state"
                    >{{ state }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="3"
          label="Contact & Eno."
          :clickable="isStepsClickable"
        >
          <div class="flex justify-center pt-7">
            <div class="w-1/3 height">
              <b-field class="file">
                <b-upload v-model="file" required expanded>
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ file.name || "Click to upload Image" }}</span>
                  </a>
                </b-upload>
              </b-field>
              <b-field label="Email" class="mb-0">
                <b-input
                  type="email"
                  v-model="studentDetails.email"
                  required
                  maxlength="50"
                ></b-input>
              </b-field>
              <b-field label="Phone Number" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.phoneNumber"
                  required
                  maxlength="10"
                ></b-input>
              </b-field>
              <div class="grid md:grid-cols-2 grid-cols-1 gap-4">

              <b-field label="Enrollment Number" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.enrollNumber"
                  required
                  maxlength="13"
                  minlength="13"
                  
                ></b-input>
              </b-field>

              <b-field label="Session" class="mb-0">
                <b-input
                  type="text"
                  v-model="studentDetails.session"
                  required
                ></b-input>
              </b-field>

              </div>
              <b-field label="Course" class="mb-0">
                <b-select
                  placeholder="Select Subject"
                  expanded
                  class="mr-2.5 mt-2.5"
                  v-model="studentDetails.course"
                  required
                >
                  <option
                    v-for="(course, index) in courses"
                    :key="index"
                    :value="course.name"
                    >{{ course.name }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          :step="4"
          label="Result"
          :clickable="isStepsClickable"
          disabled
        >
          <div class="flex justify-center pt-7">
            <div class=" height">
              <table>
                <tr v-for="(result, k) in studentDetails.results" :key="k">
                  <td scope="row" class="trashIconContainer">
                    <i
                      class="far fa-trash-alt mt-7 mr-4"
                      @click="deleteRow(k, result)"
                    ></i>
                  </td>
                  <td>
                    <!-- <b-input placeholder="subject" class="mr-2.5 mt-2.5" v-model="result.subject"></b-input> -->
                    <b-select
                      placeholder="Select Subject"
                      required
                      class="mr-2.5 mt-2.5"
                      v-model="result.subject"
                    >
                      <option
                        v-for="(subject, index) in subjects"
                        :key="index"
                        :value="subject.name"
                        >{{ subject.name }}</option
                      >
                    </b-select>
                  </td>
                  <td>
                    <b-input
                      placeholder="50"
                      class="mr-2.5 mt-2.5"
                      v-model="result.marksobtained"
                    ></b-input>
                  </td>
                  <td>
                    <b-input
                      placeholder="33"
                      class="mr-2.5 mt-2.5"
                      v-model="result.minmarks"
                    ></b-input>
                  </td>
                  <td>
                    <b-input
                      placeholder="100"
                      class="mr-2.5 mt-2.5"
                      v-model="result.maxmarks"
                    ></b-input>
                  </td>
                </tr>
              </table>

              <b-button
                type="is-success"
                class="mt-5 float-right ml-5 mr-2.5 justify-right w"
                icon-pack="fas"
                icon-left="save"
                :loading="loading"
                @click="saveData"
                >Save Details</b-button
              >
              <b-button
                type="is-primary"
                class="mt-5 float-right justify-right"
                icon-pack="fas"
                icon-left="plus"
                @click="addNewRow"
                >Add Row</b-button
              >
            </div>
          </div>
        </b-step-item>
      </b-steps>

      <div class="absolute bottom-4 right-4 ">
        <b-message
          type="is-success"
          icon-pack="far"
          class=" w-96"
          has-icon
          v-model="isSuccess"
          :auto-close="autoClose"
        >
          <span class="text-xl pt-5">Data Successfully Added </span>
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import * as firebase from "../firebase";
// import { storage } from '../firebase'
import { mapState } from "vuex";
export default {
  name: "AddStudent",
  data() {
    return {
      isSuccess: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      autoClose: true,
      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      loading: false,
      file:{},
      studentDetails: {
        enrollNumber: "",
        session:"",
        name: "",
        email: "",
        gender: "",
        dateofbirth:"",
        phoneNumber: "",
        course: "",
        father_name: "",
        mother_name: "",
        address: "",
        city: "",
        pincode: "",
        state: "",
        results: [
          
        ],
      },
    };
  },
  methods: {
    addNewRow() {
      this.studentDetails.results.push({
        subject: "",
        marksobtained: "",
        minmarks: 33,
        maxmarks: 100,
        line_total: 0,
      });
      console.log(this.file.length)
      console.log('File : ' + this.file)
    },
    deleteRow(index, result) {
      var idx = this.studentDetails.results.indexOf(result);
      console.log(idx, index);
      if (idx > -1) {
        this.studentDetails.results.splice(idx, 1);
      }
    },
    saveData() {
      this.loading = true;

 
    const storageRefs = firebase.storage.ref(`/profile/${this.file.name}`)
      storageRefs.put(this.file).then(snapshot =>{
        console.log('File Upload : ' + ((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
        snapshot.ref.getDownloadURL().then(url=>{

              console.log('Image URL : ' + url)
              console.log('CDN URL : ' + url.replace("https://firebasestorage.googleapis.com/","https://ik.imagekit.io/ard/"))

              firebase.studentCollection.doc().set({
                timestamp: new Date(),
                enrollNumber: this.studentDetails.enrollNumber,
                session:this.studentDetails.session,
                profile: {
                  primaryURL : url,
                  cdnURL : url.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/ard/"),
                },
                name: this.studentDetails.name,
                email: this.studentDetails.email,
                gender: this.studentDetails.gender,
                dateofbirth: this.studentDetails.dateofbirth,
                phoneNumber: this.studentDetails.phoneNumber,
                course: this.studentDetails.course,
                father_name: this.studentDetails.father_name,
                mother_name: this.studentDetails.mother_name,
                address: this.studentDetails.address,
                city: this.studentDetails.city,
                pincode: this.studentDetails.pincode,
                state: this.studentDetails.state,
                results: this.studentDetails.results,
              });

              this.loading = false
        })
      })

      // firebase.studentCollection.doc().set({
      //           timestamp: new Date(),
      //           enrollNumber: this.studentDetails.enrollNumber,
      //           profile: this.profile,
      //           name: this.studentDetails.name,
      //           email: this.studentDetails.email,
      //           gender: this.studentDetails.gender,
      //           dateofbirth: this.studentDetails.dateofbirth,
      //           phoneNumber: this.studentDetails.phoneNumber,
      //           course: this.studentDetails.course,
      //           father_name: this.studentDetails.father_name,
      //           mother_name: this.studentDetails.mother_name,
      //           address: this.studentDetails.address,
      //           city: this.studentDetails.city,
      //           pincode: this.studentDetails.pincode,
      //           state: this.studentDetails.state,
      //           results: this.studentDetails.results,
      //         });

      //     this.loading = false;
      //     this.isSuccess = true;
      
        }
 
      // const storageRef = firebase.storage
      //   .ref(`/profile/${this.file.name}`)
      //   .put(this.file);
      // storageRef.on(
      //   `state_changed`,
      //   (snapshot) => {
      //     console.log(
      //       "upload : " +
      //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      //     );
      //   },
      //   (error) => {
      //     console.log(error.message);
      //   },
      //   () => {
      //     storageRef.snapshot.ref.getDownloadURL().then((url) => {

      //       //Save Dta To FireStore

           
      //     });

      // this.studentDetails.enrollment = "",
      //   this.studentDetails.profile = "",
      //   this.studentDetails.name = "",
      //   this.studentDetails.email = "",
      //   this.studentDetails.gender = "",
      //   this.studentDetails.dateofbirth = "",
      //   this.studentDetails.phoneNumber = "",
      //   this.studentDetails.course = "",
      //   this.studentDetails.father_name = "",
      //   this.studentDetails.mother_name = "",
      //   this.studentDetails.address = "",
      //   this.studentDetails.city = "",
      //   this.studentDetails.pincode = "",
      //   this.studentDetails.state = "",
      //   this.studentDetails.results = [
      //     {
      //       subject: "",
      //       marksobtained: "",
      //       minmarks: 33,
      //       maxmarks: 100,
      //       line_total: 0,
      //     },
      //   ];
    
  },
  computed: {
    ...mapState(["states", "subjects", "courses"]),
  },
};
</script>

<style>
.height {
  height: 28rem !important;
}
,
.width {
  width: 12.3rem !important;
}
</style>
